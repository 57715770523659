.s-header__top {
  background-color: #30373d;
  height: 44px;
  overflow: hidden;
  opacity: 1;
  position: relative;
  z-index: 20;
  visibility: visible;
  @include transition();

  .s-header--small & {
    height: 0;
    opacity: 0;
    visibility: hidden;
  }

  .region-top-bar {
    @include max-width;
    padding: 0;
    height: inherit;

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  .block {
    height: inherit;
    display: inline-block;
    padding: 12px 15px;
    font-size: 1.1em;
    line-height: 1;
    color: $c-3;
  &:nth-child(2n) {
    float: right;
    background: $c-1;
    text-transform: uppercase;
    font-size: 0.8em;
    padding: 15px 22px;

  }
  }
}