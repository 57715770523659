.region-header {
  display: none;

  @include tablet {
    width: 250px;
    margin-right: 60px;
    padding: 40px 0;
    display: inline-block;
    vertical-align: middle;
    float: right;
    @include transition();
  }

  @include desktop {
    width: 300px;
    margin-right: 100px;



  }

  .s-header--small & {
    padding: 15px 0;
  }


  .form-group { // OVERRIDE
    margin-bottom: 0;
  }


  .btn-primary {
    color: #fff;
    background-color: $c-1;
    border-color: #2e6da4;
  padding: 4px 12px;
  font-size: 20px;

}

  .form-control {
    height: 38px;
  }


}