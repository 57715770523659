.main-container {
  table {
    width: 100%;
    thead {
      border-top: 1px solid #000;
      tr {
        background-color: #80c0e5;
      }

      th {
        border: 0;
        border-bottom: 1px solid #000;
        padding: 5px;
      }
    }
    tbody {
      border-top: 1px solid #000;

      tr {
        &:nth-of-type(odd) {
          background-color: #f9f9f9;
        }
        &:nth-child(2n) {
          background-color: #e5e5e5;
        }
      }
      td {
        border: 0;
        border-bottom: 1px solid #000;
        white-space: normal;
        padding: 5px;
      }
    }
  }
}
